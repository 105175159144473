import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import StudiesList from "./pages/StudiesList";
import UploadScan from "./pages/UploadScan";
import PatientInfo from "./pages/PatientInfo";
import Profile from "./pages/Profile";
import Reports from "./pages/Reports";
// import Dashboard from "./pages/Dashboard";
import Overview from "./pages/Overview";
import Process from "./components/Process";
import Chat from "./pages/Chat";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/list" element={<StudiesList />} />
          <Route path="/upload-scan" element={<UploadScan />} />
          <Route path="/patient-info" element={<PatientInfo />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/chat" element={<Chat/>} />
          <Route path="/process" element={<Process/>} />
          <Route path="/overview" element={<Overview/>} />
        </Route>
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
    // </div>
  );
}

export default App;
