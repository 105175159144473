import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { useAppDispatch } from "../redux/store";
import { BsFillHeartbreakFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { requestStudiesList, requestReport, updateDashboardStudy } from "../redux/OsteroCheckSlice";
import { setPathologyFromColIndex, setEmergencyCase, setSurgicalCase, setReasonData, setfilterCase } from '../redux/Report-builder/ReportBuilder';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import Navbar from "../components/Navbar";
import { FaCheck } from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoClose } from 'react-icons/io5';
import { MdOutlineQuestionMark } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import Sidebar from "../components/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { FaPlusCircle } from "react-icons/fa";
import PatientData from "../components/PatientData";
import { clearPatientData } from "../redux/OsteroCheckSlice";

const StudiesList = () => {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { modalityTypes, rowIndex, colIndex } = location.state || {};
  const modality = ["ChestR", "SXR", "MRI spine", "CT brain", "KXR", "CT Abdomen Plain and Contrast", "CT PNS", "MRI Knee", "CT Throax", "CT KUB", "CT Temporal Bone"]
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(200);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const [selectedModalities, setSelectedModalities] = useState([]);
  const [selectedPathologies, setSelectedPathologies] = useState([]);
  const [filteredStudies, setFilteredStudies] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredStudies.slice(indexOfFirstItem, indexOfLastItem);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudyId, setSelectedStudyId] = useState(null);
  const [comments, setComments] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const filterEmergency = useSelector(state => state.reportBuilder.emergencycase);
  const [openTooltipId, setOpenTooltipId] = useState(null);
  const showSurgicalOnly = useSelector(state => state.reportBuilder.surgicalcase);
  const modalityFilterData = useSelector((state) => state.reportBuilder.filterModalities)['', '', '']
  const [emergencyChecked, setEmergencyChecked] = useState(false);
  const [surgicalChecked, setSurgicalChecked] = useState(false);


  const studies = useSelector(
    (state) => state.OsteroCheck.patientList
  );


  useEffect(() => {
    if (location.pathname === "/list") {
      sessionStorage.removeItem("selectedDropdownValue");
      sessionStorage.removeItem("selectedModStudy");
      sessionStorage.removeItem("studyId");
      sessionStorage.removeItem("formdatademo");
      sessionStorage.removeItem("patientData");
    }
  }, [location]);


  useEffect(() => {
    if (location.state && location.state.selectedModalities) {
      setSelectedModalities(location.state.selectedModalities);
    }
  }, [location]);

  useEffect(() => {
    if (studies.data && studies.data.data) {
      let filtered = studies.data.data;

      if (dateRange[0].startDate && dateRange[0].endDate) {
        filtered = filtered.filter(study => {
          const studyDate = new Date(study.created_at);
          return studyDate >= dateRange[0].startDate && studyDate <= dateRange[0].endDate;
        });
      }

      if (selectedModalities.length > 0) {
        filtered = filtered.filter(study => selectedModalities.includes(study.mod_study));
      }

      if (selectedPathologies.length > 0) {
        filtered = filtered.filter(study => {
          if (study.json && study.json.pathologies && Array.isArray(study.json.pathologies) && study.json.pathologies.length > 0) {
            const studyPathologies = study.json.pathologies[0];
            return selectedPathologies.some(pathology => studyPathologies[pathology] === true);
          }
          return false;
        });
      }

      if (selectedStatus.length > 0) {
        filtered = filtered.filter(study => selectedStatus.includes(study.status));
      }

      if (searchQuery) {
        filtered = filtered.filter(study =>
          study.patient_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      // if (filterEmergency) {
      //   filtered = filtered.filter(study => study.action && study.action.emergency);
      // }

      if (showSurgicalOnly) {
        filtered = filtered.filter(study => study.surgical);
      }

      setFilteredStudies(filtered);
      setCurrentPage(1);
    }
  }, [studies, dateRange, selectedModalities, selectedPathologies, selectedStatus, searchQuery, filterEmergency, showSurgicalOnly]);

  const handleDataFiltered = (filteredData) => {
    setFilteredStudies(filteredData);
  };




  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await appDispatch(requestStudiesList());
      setIsLoading(false);
    };
    fetchData();
  }, [appDispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openTooltipId && !event.target.closest('.surgical-tooltip')) {
        setOpenTooltipId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openTooltipId]);

  const navigate = useNavigate();

  const handleReportView = (order_id, status, reasonData) => {
    if (status.toLowerCase() === 'in progress') {
      navigate("/process");
    } else {
      appDispatch(requestReport(order_id));
      console.log("surgicaldata", reasonData)
      appDispatch(setReasonData(reasonData));
      navigate({
        pathname: "/reports",
        search: "?type=completed"
      });
    }
  };

  const tableRow = (row) => {


    const formatDateTime = (dateString) => {
      const date = new Date(dateString);

      const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      });

      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });

      return { date: formattedDate, time: formattedTime };
    };


    let modality = row["mod_study"];

    if (modality === "ChestR") {
      modality = "XRAY Radiograph Chest";
    } else if (modality === "SXR") {
      modality = "XRAY Radiograph Spine";
    } else if (modality === "MRI spine") {
      modality = "MRI Spine Contrast ";
    } else if (modality === "CT brain") {
      modality = "CT Brain Plain";
    } else if (modality === "KXR") {
      modality = "XRAY Radiograph Knee";
    }else if (modality === "mribrain") {
      modality = "MRI Brain";
    }




    return (
      <div className="flex border-b items-center text-[14px] sm:text-[10px] sm:mx-0 p-3 w-full ">

        <div className="p-2 w-[12%] sm:p-1 flex  justify-start items-center ml-[5rem]">
          <div className="text-center flex flex-col gap-2 ">
            {row["patient_name"]} ({row["patient_sex"].charAt(0).toUpperCase()} / {row["patient_age"]})
            {/* <div className="flex gap-2">
              {row['action'].emergency && <FaPlusCircle size={20} className="text-[#f27e12]" />}
              {row["surgical"] && (
                <SurgicalTooltip
                  surgical={row["surgical"]}
                  isOpen={openTooltipId === row["order_id"]}
                // onClose={handleTooltipClose}
                >
                  <BsFillHeartbreakFill
                    className="text-[#c51212] heartbeat"
                    size={20}
                  // onClick={(event) => handleTooltipToggle(row["order_id"], event)}
                  />
                </SurgicalTooltip>
              )}
            </div> */}
          </div>
        </div>
        <div className="p-2 w-1/6 sm:p-1 flex flex-col justify-center items-center">
          <div className="tracking-wide text-center">{formatDateTime(row["created_at"]).date}</div>
          <div className=" inline-flex items-center rounded-2xl text-black text-opacity-90  bg-[#e3eaf6] font-light text-[12px] px-2 py-1 mt-1">
            {formatDateTime(row["created_at"]).time}
          </div>
        </div>
        <div className="p-2 w-1/6 sm:p-1 flex justify-center items-center text-center ">{modality}</div>
        <div className="p-2  w-1/6 sm:p-1  justify-center items-center !text-center ">
          {row['status'].toLowerCase() !== 'inprogress'
            ? (row.json && row.json.pathologies && row.json.pathologies[0]
              ? (() => {
                const text = Object.entries(row.json.pathologies[0])
                  .filter(([key, value]) => value === true)
                  .map(([key]) => key)
                  .join(", ");

                const maxLength = 30;
                return text.length > maxLength
                  ? text.slice(0, maxLength) + "..."
                  : text;
              })()
              : "")
            : "N/A"}
        </div>



        <div className="p-2  sm:p-1 w-1/6 flex justify-center items-center ">
          <div className={`inline-block rounded-lg px-2 py-1 tracking-wide ${row['status'] === 'AI Autonomous'
            ? 'bg-[#e9f8f5] text-[#07947f]'
            : row['status'] === 'AI Assisted'
              ? 'bg-[#E9F3FF] text-[#4359A5]'
              : 'bg-[#FFF7E4] text-[#D67300]'
            }`}>
            {row['status']}
          </div>
        </div>
        <div className="p-2 w-1/6 flex justify-center items-center sm:p-1">
          {/* <img
            className="object-contain cursor-pointer pr-1 w-10"
            alt=""
            src="/img/whatsup.svg"
          />
          <div className="relative inline-block group pr-1">
            <button
              className={`p-1 text-white rounded-full ${row['action'].case
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-green-500 hover:bg-green-600'
                }`}
              onClick={() => !row['action'].case && !row['action'].feedback && handleCheckClick(row["order_id"])}
              disabled={row['action'].case || row['action'].feedback}
            >
              <FaCheck size={23} />
            </button>
            <p className="absolute w-28 px-2 py-1 text-center text-xs text-white bg-black rounded-lg shadow-lg 
      bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2
      opacity-0 group-hover:opacity-100 transition-opacity duration-300
      pointer-events-none">
              {row['action'].case ? 'Case accepted' : 'Accept case'}
            </p>
          </div>
          <div className="relative inline-block group">
            <button
              className={`p-1 text-white rounded-full  ${row['action'].feedback
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-red-500 hover:bg-red-600'
                }`}
              onClick={() => !row['action'].case && handleSendFeedback(row["order_id"])}
              disabled={row['action'].case || row['action'].feedback}
            >
              <MdOutlineQuestionMark size={22} />
            </button>
            <p className="absolute w-28 px-2 py-1 text-center text-xs text-white bg-black rounded-lg shadow-lg 
      bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2
      opacity-0 group-hover:opacity-100 transition-opacity duration-300
      pointer-events-none">
              Send Feedback
            </p>
          </div>
          <button
            className='bg-white text-[#4359A5] rounded-full'
            onClick={() => handleReportView(row["order_id"], row["status"])}
          >
            <IoIosArrowDroprightCircle size={40} />
          </button> */}
          <div className="p-2  flex items-center justify-center sm:p-1 ">
            <button
              className="flex items-center bg-[#e3eaf6] hover:bg-[#f3f6fb] group hover:bg-logo-blue text-logo-blue font-semibold hover:text-white py-1 px-2 border border-logo-blue hover:border-transparent rounded"
              onClick={() => handleReportView(row["order_id"], row["status"], row["surgical"])}
            >
              <AiOutlineEye className="w-4 h-4 mr-2 text-logo-blue text-[#4359A5]  sm:mr-0" />

              <span className=" sm:hidden text-[#4359A5] font-medium ">View</span>
            </button>
          </div>
        </div>
      </div>
    );
  };


  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSubmitComment = async () => {
    if (selectedStudyId && comments.trim()) {
      setIsSubmitting(true);
      setError(null);
      try {
        const selectedStudy = filteredStudies.find(study => study.order_id === selectedStudyId);

        const emergencyValue = selectedStudy?.action?.emergency || false;
        await appDispatch(updateDashboardStudy({
          study_id: selectedStudyId,
          action: { case: false, feedback: true, emergency: emergencyValue },
          comment: comments
        })).unwrap();
        closeModal();
        appDispatch(requestStudiesList())
      } catch (err) {
        setError("Failed to submit comment. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStudyId(null);
    setComments('');
  };

  const handleCheckboxChange = (type) => {
    const newEmergencyChecked = type === 'emergency' ? !emergencyChecked : emergencyChecked;
    const newSurgicalChecked = type === 'surgical' ? !surgicalChecked : surgicalChecked;

    setEmergencyChecked(newEmergencyChecked);
    setSurgicalChecked(newSurgicalChecked);

    const newFilterCase = [];
    if (newEmergencyChecked) newFilterCase.push('emergency');
    if (newSurgicalChecked) newFilterCase.push('surgical');

    appDispatch(setfilterCase(newFilterCase));
  };

  const handleAddClick = () => {
    dispatch(clearPatientData());
    navigate("/patient-info");
  };


  return (
    <div className="flex flex-col h-screen w-screen bg-[#f5f6fa] ">
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSubmitComment}
        isSubmitting={isSubmitting}
        error={error}
        comments={comments}
        setComments={setComments}
      />
      <ToastContainer />
      <div className="border rounded-md border-solid shadow-lg bg-white ">
        <Navbar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {/* <Sidebar /> */}
        <div className="flex-1 overflow-y-auto">
          <div className=" bg-[#f5f6fa] pt-4 px-8 ">
            <div className="flex justify-between items-center py-4">
              <div className=" flex gap-4 w-full">
                <h1 className="font-bold text-2xl sm:text-base sm:w-1/2">Case List</h1>
                <div className="relative flex items-center border border-solid p-[0.5rem] pl-2 mr-2 rounded-3xl gap-[0.5rem] focus-within:shadow-lg bg-white overflow-hidden w-[20%] 2xl:w-[12%] ">
                  <div className="grid place-items-start h-full  text-gray-30 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>

                  <input
                    className="peer h-full w-full outline-none text-sm text-gray-700"
                    type="search"
                    id="search"
                    placeholder="Name search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                {/* <div className="flex gap-4">
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      checked={emergencyChecked}
                      className="checkbox"
                      onChange={() => handleCheckboxChange('emergency')}
                    />
                    <label>Emergency</label>
                  </div>
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      checked={surgicalChecked}
                      className="checkbox"
                      onChange={() => handleCheckboxChange('surgical')}
                    />
                    <label>Surgical</label>
                  </div>
                </div> */}

              </div>
              <div className="flex">

                <button
                  className="bg-[#4359A5] hover:bg-gray-200 group hover:text-black text-white font-bold px-8 py-2 cursor-pointer rounded inline-flex gap-1 items-center sm:px-3"
                  onClick={handleAddClick}
                >
                  <FiPlusCircle className="bg-[#4359A5] text-white rounded-full" />
                  <span className="font-semibold sm:hidden">ADD</span>
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 px-2 2xl:px-8">
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                headers={[
                  "Patient Details",
                  "Date",
                  "Study",
                  "Pathology",
                  "Status",
                  "Action"
                ]}
                tableRow={tableRow}
                data={currentItems}
                onDataFiltered={handleDataFiltered}
              />
            )}
            {/* {!isLoading && (
               <Pagination
               currentPage={currentPage}
               totalItems={filteredStudies.length}
               itemsPerPage={itemsPerPage}
               paginate={paginate}
               setItemsPerPage={handleItemsPerPageChange}
             />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

const TableSkeleton = () => {
  return (
    <div className="animate-pulse">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex border-b items-center p-3">
          <div className="p-2 w-1/6 flex justify-center items-center">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
          <div className="p-2 w-1/6 flex justify-center items-center">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
          <div className="p-2 w-1/6 flex justify-center items-center">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
          <div className="p-2 w-1/6 flex justify-center items-center">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
          <div className="p-2 w-1/6 flex justify-center items-center">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
          <div className="p-2 w-1/6 flex justify-center items-center">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

const SurgicalTooltip = ({ children, surgical, isOpen, onClose }) => {
  return (
    <div className="relative inline-block">
      {children}
      {isOpen && (
        <div className="surgical-tooltip bg-[#ffc6c6] text-black text-xs -right-[17rem] rounded-lg py-2 px-3 absolute z-[99] mt-2 min-w-[300px]">
          <button onClick={onClose} className="absolute top-0 right-0 mt-1 mr-1 text-black"><IoClose size={20} /></button>
          <p className=" pb-2 text-left pr-4"><strong>Surgical Pathology:</strong> {surgical.surgical_pathology.join(", ")}</p>
          <p className=" text-left"><strong>Reason:</strong> {surgical.Reason}</p>
          <div className="absolute bottom-full  border-transparent border-b-[#ffc6c6]  border-8"></div>
        </div>
      )}
    </div>
  );
};

const Pagination = ({ currentPage, totalItems, itemsPerPage, paginate, setItemsPerPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav role="navigation" aria-label="Pagination Navigation" className="flex items-center justify-end mt-4">
      <div className="flex items-center space-x-2">
        <button
          onClick={() => paginate(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>

        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md ${currentPage === number
              ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
              : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
              }`}
          >
            {number}
          </button>
        ))}

        <button
          onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
};

const Modal = ({ isOpen, onClose, onSubmit, isSubmitting, error, comments, setComments }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-1/3 relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <IoClose size={24} />
        </button>
        <h2 className="text-xl font-bold mb-4">Send Feedback</h2>
        <textarea
          className="w-full p-2 border rounded"
          rows="4"
          placeholder="Enter your feedback here..."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        ></textarea>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <div className='flex gap-3 justify-end'>
          <button onClick={onClose} className="mt-4 bg-white text-red-500 px-4 py-2 font-semibold border-[1.5px] border-solid border-red-500 rounded hover:bg-red-500 hover:text-white" disabled={isSubmitting}>
            Close
          </button>
          <button onClick={onSubmit} className="mt-4 bg-white text-[#1777ff] font-semibold px-4 py-2 border-[1.5px] border-solid border-[#1777ff] rounded hover:bg-[#1777ff] hover:text-white" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  comments: PropTypes.string.isRequired,
  setComments: PropTypes.func.isRequired,
};



export default StudiesList;