import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'

function Chat() {
  return (
    <div className="flex flex-col h-screen w-screen bg-[#f5f6fa]">
      <div className="border-b border-solid bg-white">
        <Navbar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {/* <Sidebar /> */}
        <main className="flex-1 overflow-y-auto">
          <div className="flex justify-center items-center h-full">
            <img src='./img/soon.png' className='w-2/5 max-w-md' alt="Coming Soon" />
          </div>
        </main>
      </div>
    </div>
  )
}

export default Chat