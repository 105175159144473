import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import { useSelector } from "react-redux";
import 'react-date-range/dist/theme/default.css';
import { FixedSizeList as List } from 'react-window';
import { FaFilterCircleXmark } from "react-icons/fa6";

const DateRangePicker = ({ dateRange, onChange, onClose,onClear }) => {
  return (
    <div className="absolute z-10 bg-white border rounded shadow-lg p-4 mt-4 left-0">
      <DateRange
        ranges={[dateRange]}
        onChange={onChange}
        months={1}
        direction="vertical"
      />
      <div className="flex justify-between mt-2">
        <button onClick={onClose} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
          Apply
        </button>
        <button onClick={onClear} className="px-4 py-2 bg-[#b0b0b0] text-white rounded hover:bg-red-600 transition-colors">
          Clear
        </button>
      </div>
    </div>
  );
};


const StudyFilter = ({ modalities, onFilterApply, selectedModalities }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleModalityToggle = useCallback((modality) => {
    const newSelection = selectedModalities.includes(modality)
      ? selectedModalities.filter(m => m !== modality)
      : [...selectedModalities, modality];
    onFilterApply(newSelection.length > 0 ? newSelection : null);
  }, [selectedModalities, onFilterApply]);

  const handleClearFilter = useCallback(() => {
    onFilterApply(null);
  }, [onFilterApply]);

  const getModalityLabel = (modality) => {
    switch (modality) {
      case "ChestR":
        return "XRAY Radiograph Chest";
      case "SXR":
        return "XRAY Radiograph Spine";
      case "MRI spine":
        return "Contrast MRI Spine";
      case "CT brain":
        return "CT Brain plain";
      case "KXR":
        return "XRAY Radiograph knee";
      case "mribrain":
        return "MRI Brain";
      default:
        return modality;
    }
  };

  const filteredModalities = useMemo(() => {
    return modalities.filter(modality =>
      getModalityLabel(modality).toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [modalities, searchTerm]);

  return (
    <div className="absolute z-10 bg-white border rounded shadow-lg p-4 mt-[24rem] w-72 max-h-80" onClick={(e) => e.stopPropagation()}>
      <div className="flex justify-center items-center">
        <input
          type="text"
          placeholder="Search modalities..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 mb-2 border rounded text-black font-normal"
        />
        <FaFilterCircleXmark size={25} className="ml-2 text-[#888888] cursor-pointer" onClick={handleClearFilter} />
      </div>

      <div className="max-h-[200px] overflow-y-auto mb-2">
        {filteredModalities.map((modality, index) => (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleModalityToggle(modality);
            }}
            className={`p-2 cursor-pointer transition-colors text-left ${selectedModalities.includes(modality)
                ? 'bg-blue-500 text-white'
                : 'text-black hover:bg-gray-200'
              }`}
          >
            <span className="text-sm font-normal leading-6 text-left">
              {getModalityLabel(modality)}
            </span>
          </div>
        ))}
      </div>
      {/* <button 
        onClick={handleClearFilter}
        className="w-full p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
      >
        Clear Filter
      </button> */}
    </div>
  );
};

const PathologyFilter = ({ availablePathologies, selectedPathologies, onFilterApply }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handlePathologyToggle = useCallback((pathology) => {
    const newSelection = selectedPathologies.includes(pathology)
      ? selectedPathologies.filter(p => p !== pathology)
      : [...selectedPathologies, pathology];
    onFilterApply(newSelection.length > 0 ? newSelection : null);
  }, [selectedPathologies, onFilterApply]);

  const filteredPathologies = useMemo(() => {
    let pathologiesToShow = [
      ...new Set(['Normal', ...availablePathologies.filter(p => p.toLowerCase() !== 'normal')])
    ];

    return pathologiesToShow.filter(pathology =>
      pathology.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [availablePathologies, searchTerm]);

  const handleClearFilter = useCallback(() => {
    onFilterApply(null);
  }, [onFilterApply]);

  return (
    <div className="absolute z-10 bg-white border rounded shadow-lg p-4 mt-[24rem] w-72 max-h-80" onClick={(e) => e.stopPropagation()}>
      <div className="flex justify-center items-center">
        <input
          type="text"
          placeholder="Search modalities..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 mb-2 border rounded text-black font-normal"
        />
        <FaFilterCircleXmark size={25} className="ml-2 text-[#888888] cursor-pointer" onClick={handleClearFilter} />
      </div>
      <div className="max-h-[240px] overflow-y-auto">
        {filteredPathologies.map((pathology, index) => (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handlePathologyToggle(pathology);
            }}
            className={`p-2 cursor-pointer transition-colors text-left rounded-md ${selectedPathologies.includes(pathology)
                ? 'bg-blue-500 text-white '
                : 'text-black hover:bg-gray-200'
              }`}
          >
            <span className="text-sm font-normal leading-6 text-left">
              {pathology}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const StatusFilter = ({ availableStatuses, selectedStatuses, onFilterApply }) => {
  const handleStatusToggle = (status) => {
    const newSelection = selectedStatuses.includes(status)
      ? selectedStatuses.filter(s => s !== status)
      : [...selectedStatuses, status];

    onFilterApply(newSelection.length > 0 ? newSelection : null);
  };

  return (
    <div className="absolute z-10 bg-white border rounded shadow-lg p-4 mt-[15rem] w-72">
      <div className="max-h-60 overflow-y-auto">
        {availableStatuses.map(status => (
          <div
            key={status}
            onClick={() => handleStatusToggle(status)}
            className={`p-2 mb-2 rounded cursor-pointer transition-colors text-left ${selectedStatuses.includes(status)
              ? 'bg-blue-500 text-white'
              : 'text-black hover:bg-gray-200'
              }`}
          >
            <span className="text-sm font-normal leading-6 text-left">
              {status}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};


const TableHead = ({ header, showFilter, onFilterClick, isFilterOpen, children, filterCount, isDateFilter, dateFilterActive, isFilterActive }) => {
  return (
    <div className="p-2 font-semibold text-center sm:p-1 sm:text-[10px] text-[15px] sm:w-full flex flex-col items-center justify-center relative">
      <div className="flex items-center">
        {header}
        {showFilter && (
          <div className="flex items-center">
            {isFilterActive ? (
              <MdFilterAlt
                className="ml-1 w-4 h-4 cursor-pointer text-white"
                onClick={() => onFilterClick(header)}
              />
            ) : (
              <MdFilterAltOff
                className="ml-1 w-4 h-4 cursor-pointer"
                onClick={() => onFilterClick(header)}
              />
            )}
            {filterCount > 0 && (
              <span className="ml-1 text-xs bg-blue-500 text-white rounded-full px-2 py-1">
                {filterCount}
              </span>
            )}
          </div>
        )}
      </div>
      {isFilterOpen && children}
    </div>
  );
};

const Table = (props) => {
  const { headers, data, tableRow } = props;
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const [activeFilter, setActiveFilter] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [selectedModalities, setSelectedModalities] = useState([]);
  const [availablePathologies, setAvailablePathologies] = useState([]);
  const [selectedPathologies, setSelectedPathologies] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [dateFilterActive, setDateFilterActive] = useState(false);

  const filterRef = useRef(null);

  const modalities = ["ChestR", "SXR", "MRI spine", "CT brain", "KXR","mribrain", "lifesaver", "CT Abdomen Plain and Contrast", "CT PNS", "MRI Knee", "CT Throax", "CT KUB", "CT Temporal Bone"];
  const availableStatuses = ["AI Autonomous", "AI Assisted", "In progress"];

  const studies = useSelector((state) => state.OsteroCheck.patientList.data.data);
  const filterableHeaders = headers.map(header => !['Patient Details', 'Action','Status','Study'].includes(header));
  const [isAbnormalSelected, setIsAbnormalSelected] = useState(false);
  const [isAbnormalFilterActive, setIsAbnormalFilterActive] = useState(false);
  const [filterCase, setFilterCase] = useState([]);
  const [renderedData, setRenderedData] = useState([]);
  const filterModalities = useSelector(state => state.reportBuilder.filterModalities)
  const filterStatusValue = useSelector(state => state.reportBuilder.filterStatus)
  const filterPathology = useSelector(state => state.reportBuilder.filterPathology)
  const filterCaseValue = useSelector(state =>
    state.reportBuilder.filterCase.map(caseType => caseType.toLowerCase())
  );





  useEffect(() => {
    if (Array.isArray(studies) && studies.length > 0) {
      const pathologies = studies.reduce((acc, study) => {
        if (study && study.json && study.json.pathologies &&
          Array.isArray(study.json.pathologies) && study.json.pathologies.length > 0 &&
          study.status.toLowerCase() !== 'in progress') {
          Object.entries(study.json.pathologies[0]).forEach(([key, value]) => {
            if (value === true && !acc.includes(key)) {
              acc.push(key);
            }
          });
        }
        return acc;
      }, []);
      setAvailablePathologies(pathologies);
    } else {
      setAvailablePathologies([]);
      console.warn('Studies prop is not an array or is empty');
    }
  }, [studies]);

  //filtermodality
  useEffect(() => {
    if (filterModalities && filterModalities.length > 0) {
      setSelectedModalities(filterModalities);
      applyStudyFilter(filterModalities);
    }
  }, [filterModalities]);
  //filterstatus
  useEffect(() => {
    if (filterStatusValue && filterStatusValue.length > 0) {
      setSelectedStatuses(filterStatusValue);
    }
  }, [filterStatusValue]);

  //filterpathology
  useEffect(() => {
    if (filterPathology && filterPathology.length > 0) {
      const normalPathology = filterPathology.find(p => p.toLowerCase() === 'normal');
      if (normalPathology) {
        setIsAbnormalFilterActive(false);
        setSelectedPathologies([normalPathology]);
      } else if (filterPathology.includes('Abnormal')) {
        setIsAbnormalFilterActive(true);
        setSelectedPathologies([]);
      } else {
        setIsAbnormalFilterActive(false);
        setSelectedPathologies(filterPathology);
      }
    } else {
      setIsAbnormalFilterActive(false);
      setSelectedPathologies([]);
    }
  }, [filterPathology]);

  //filteremergency
  // useEffect(() => {
  //   if (filterCaseValue && filterCaseValue.length > 0) {
  //     setFilterCase(filterCase.map(caseType => caseType.toLowerCase()));
  //   } else {
  //     setFilterCase([]);
  //   }
  // }, [filterCaseValue]);

  useEffect(() => {
    if (filterCaseValue && filterCaseValue.length > 0) {
      setFilterCase(filterCaseValue.map(caseType => caseType.toLowerCase()));
    } else {
      setFilterCase([]);
    }

  }, [filterCaseValue]);

  useEffect(() => {
    if (props.selectedModalities && props.selectedModalities.length > 0) {
      const filtered = data.filter(row => props.selectedModalities.includes(row.mod_study));
      setFilteredData(filtered);
      setSelectedModalities(props.selectedModalities);
    }
  }, [props.selectedModalities]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setActiveFilter(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  const handleFilterClick = (header) => {
    setActiveFilter(prev => prev === header ? null : header);
  };

  const handleDateChange = (item) => {
    setDateRange(item.selection);
  };

  // const applyDateFilter = () => {
  //   const filtered = data.filter(row => {
  //     const rowDate = new Date(row.created_at);
  //     return rowDate >= dateRange.startDate && rowDate <= dateRange.endDate;
  //   });
  //   setFilteredData(filtered);
  //   setActiveFilter(null);
  //   setDateFilterActive(true);
  // };


  // const applyStudyFilter = (newSelectedModalities) => {
  //   setSelectedModalities(newSelectedModalities || []);
  //   if (!newSelectedModalities || newSelectedModalities.length === 0) {
  //     setFilteredData(data);
  //   } else {
  //     const filtered = data.filter(row => newSelectedModalities.includes(row.mod_study));
  //     setFilteredData(filtered);
  //   }
  //   setActiveFilter(null);
  // };

  // const applyPathologyFilter = (newSelectedPathologies) => {
  //   setSelectedPathologies(newSelectedPathologies || []);
  //   if (!newSelectedPathologies || newSelectedPathologies.length === 0) {
  //     setFilteredData(data);
  //   } else {
  //     const filtered = data.filter(row => {
  //       if (row.json && row.json.pathologies && Array.isArray(row.json.pathologies) && row.json.pathologies.length > 0) {
  //         const rowPathologies = row.json.pathologies[0];
  //         return newSelectedPathologies.some(pathology => rowPathologies[pathology] === true);
  //       }
  //       return false;
  //     });
  //     setFilteredData(filtered);
  //   }
  //   setActiveFilter(null);
  // };

  // const applyStatusFilter = (newSelectedStatuses) => {
  //   setSelectedStatuses(newSelectedStatuses || []);
  //   if (!newSelectedStatuses || newSelectedStatuses.length === 0) {
  //     setFilteredData(data);
  //   } else {
  //     const filtered = data.filter(row => newSelectedStatuses.includes(row.status));
  //     setFilteredData(filtered);
  //   }
  //   setActiveFilter(null);
  // };



  const applyAllFilters = (dataToFilter) => {
    let filtered = dataToFilter;

    if (selectedModalities.length > 0) {
      filtered = filtered.filter(row => selectedModalities.includes(row.mod_study));
    }

    if (isAbnormalFilterActive) {
      filtered = filtered.filter(row => {
        if (row.json && row.json.pathologies && Array.isArray(row.json.pathologies) && row.json.pathologies.length > 0) {
          const rowPathologies = row.json.pathologies[0];
          const hasAbnormalPathology = Object.entries(rowPathologies).some(([key, value]) =>
            key.toLowerCase() !== 'normal' && value === true
          );
          return hasAbnormalPathology;
        }
        return false;
      });
    } else if (selectedPathologies.length > 0) {
      filtered = filtered.filter(row => {
        if (row.json && row.json.pathologies && Array.isArray(row.json.pathologies) && row.json.pathologies.length > 0) {
          const rowPathologies = row.json.pathologies[0];
          const isNormalSelected = selectedPathologies.some(p => p.toLowerCase() === 'normal');
          if (isNormalSelected) {
            const hasNoPathologies = !Object.entries(rowPathologies).some(([key, value]) =>
              key.toLowerCase() !== 'normal' && value === true
            );
            const hasNormalPathology = Object.entries(rowPathologies).some(([key, value]) =>
              key.toLowerCase() === 'normal' && value === true
            );
            return hasNoPathologies || hasNormalPathology;
          }
          return selectedPathologies.some(pathology =>
            Object.entries(rowPathologies).some(([key, value]) =>
              key.toLowerCase() === pathology.toLowerCase() && value === true
            )
          );
        }
        return false;
      });
    }

    if (selectedStatuses.length > 0) {
      filtered = filtered.filter(row => selectedStatuses.includes(row.status));
    }

    if (dateFilterActive) {
      filtered = filtered.filter(row => {
        const rowDate = new Date(row.created_at);
        return rowDate >= dateRange.startDate && rowDate <= dateRange.endDate;
      });
    }

    // if (filterCaseValue.some(caseType => caseType.toLowerCase() === 'emergency')) {
    //   filtered = filtered.filter(row =>
    //     row.action &&
    //     (row.action.emergency === true ||
    //       row.action.Emergency === true ||
    //       row.action.EMERGENCY === true)
    //   );
    // }

    if (filterCaseValue.some(caseType => caseType.toLowerCase() === 'surgical')) {
      filtered = filtered.filter(row =>
        row.surgical &&
        (row.surgical.surgical === true ||
          row.surgical.Surgical === true ||
          row.surgical.SURGICAL === true)
      );
    }

    return filtered;
  };


  useEffect(() => {
    const newFilteredData = applyAllFilters(data);
    setFilteredData(newFilteredData);
  }, [data, selectedModalities, selectedPathologies, selectedStatuses, dateFilterActive, dateRange, filterCaseValue]);


  const applyStudyFilter = (newSelectedModalities) => {
    setSelectedModalities(newSelectedModalities || []);
    setActiveFilter(null);
  };

  const applyPathologyFilter = (newSelectedPathologies) => {
    setSelectedPathologies(newSelectedPathologies || []);
    setActiveFilter(null);
  };

  const applyStatusFilter = (newSelectedStatuses) => {
    setSelectedStatuses(newSelectedStatuses || []);
    setActiveFilter(null);
  };

  const applyDateFilter = () => {
    setDateFilterActive(true);
    setActiveFilter(null);
  };

  useEffect(() => {
    let isMounted = true;
    const renderRows = async () => {
      for (let i = 0; i < filteredData.length; i++) {
        if (!isMounted) break;
        setRenderedData(prevData => [...prevData, filteredData[i]]);
        await new Promise(resolve => setTimeout(resolve, 100)); // Adjust the delay as needed
      }
    };
    renderRows();
    return () => {
      isMounted = false;
    };
  }, [filteredData]);

  const clearDateFilter = () => {
    setDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    });
    setDateFilterActive(false);
    setActiveFilter(null);
  };



  return (
    <div className="rounded-2xl overflow-hidden border">
      <div className="flex bg-[#4359A5] sm:px-2 p-2 text-[#ffffff]">
        {headers.map((header, index) => (
          <div key={index} className="w-1/6 flex justify-center items-center fade-in" ref={activeFilter === header ? filterRef : null}>
              <TableHead
      header={header}
      showFilter={filterableHeaders[index]}
      onFilterClick={handleFilterClick}
      isFilterOpen={activeFilter === header}
      filterCount={
        header === 'Study' ? selectedModalities.length :
        header === 'Pathology' ? selectedPathologies.length :
        header === 'Status' ? selectedStatuses.length : 0
      }
      isDateFilter={header === 'Date'}
      dateFilterActive={dateFilterActive}
      isFilterActive={
        header === 'Date' ? dateFilterActive :
        header === 'Study' ? selectedModalities.length > 0 :
        header === 'Pathology' ? selectedPathologies.length > 0 :
        header === 'Status' ? selectedStatuses.length > 0 : false
      }
    >
              {header === 'Date' && (
                <div>
                  <DateRangePicker
                    dateRange={dateRange}
                    onChange={handleDateChange}
                    onClose={applyDateFilter}
                    onClear={clearDateFilter}
                  />
                </div>
              )}
              {header === 'Study' && (
                <StudyFilter
                  modalities={modalities}
                  onFilterApply={applyStudyFilter}
                  selectedModalities={selectedModalities}
                />
              )}
              {header === 'Pathology' && (
                <PathologyFilter
                  availablePathologies={availablePathologies}
                  selectedPathologies={selectedPathologies}
                  onFilterApply={applyPathologyFilter}
                />
              )}
              {/* {header === 'Status' && (
                <StatusFilter
                  availableStatuses={availableStatuses}
                  selectedStatuses={selectedStatuses}
                  onFilterApply={applyStatusFilter}
                />
              )} */}
            </TableHead>
          </div>
        ))}
      </div>
      <div className="overflow-y-auto h-[70vh] 2xl:h-[75vh] fade-in bg-[#ffffff]">
        {filteredData.map((row, index) => tableRow(row, index))}
      </div>
    </div>
  );
};



export default Table;
