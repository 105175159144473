import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { useNavigate } from "react-router-dom";

function Process() {
    const navigate=useNavigate();
  return (
    <div className="flex flex-col h-screen w-screen bg-[#f5f6fa]">
    <div className="border-b border-solid bg-white">
        <Navbar />
    </div>
    <div className="flex flex-1 overflow-hidden">
        {/* <Sidebar /> */}
        <main className="flex-1 flex items-center justify-center overflow-y-auto">
            <div className="flex flex-col items-center text-center">
                <div className="mb-4 animate-slide-in">
                <iframe src="https://lottie.host/embed/8c2c86f1-d090-43e4-b9e7-d2d8c319306d/F1bExpGldm.json" className=' w-64 h-64'></iframe>
                </div>
                <p className='w-[40%] text-pretty'>
                    Your request is being processed by our AI. Please wait a moment or return to the 
                    <span 
                        className='text-[#4359A5] underline font-semibold cursor-pointer ml-1' 
                        onClick={() => navigate('/list')}
                    >
                        homepage
                    </span> in the meantime
                </p>
            </div>
        </main>
    </div>
</div>

  )
}

export default Process